import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import UnProtectedLayout from 'components/layouts/UnProtectedLayout';
import { SITE } from 'config/config';
import { CONST_SITE } from 'utils/common/const';

const HomePage = lazy(() => import('pages/Home'));
const New88Page = lazy(() => import('pages/New88'));
const PageError = lazy(() => import('pages/Error'));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UnProtectedLayout />}>
        {/* <Route index element={<HomePage />} /> */}
        <Route index element={SITE === CONST_SITE.NEW88 ? <New88Page /> : <HomePage />} />
      </Route>
      <Route path="*" element={<PageError />} />
    </Routes>
  );
};

export default AppRoutes;
